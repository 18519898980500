<template>
  <div>
    <CartMain :ifPrev="ifPrev"/>
    <CartRecommend/>
  </div>
</template>

<script>
import CartMain from '@/components/cart/CartMain.vue';
import CartRecommend from '@/components/cart/CartRecommend.vue';

export default {
  name: 'CartPage',
  props: ['ifPrev'],
  components: {
    CartRecommend,
    CartMain,
  },
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
  },
  watch: {
  },
  mounted() {
  },
};
</script>
<style scoped>
.ooxx {
  font-size: 20px;
  font-family: 'Courier New', Courier, monospace;
  font-weight: 800;
  padding: 4px;
  border: solid 1px #000000;
}
.main_link {
  font-size: 50px;
}
</style>
